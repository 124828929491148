const LOGGING_ENABLED = false;

const CSS = `background: #000; color: #fff; padding: 8px;`;
export const log = (message: string) => {
	if (LOGGING_ENABLED) {
		console.log(`%c${message}`, CSS);
	}
};

export const logJson = (message: string, json: unknown) => {
	if (LOGGING_ENABLED) {
		console.groupCollapsed(`%c${message}`, CSS);
		console.log(json);
		console.groupEnd();
	}
};
