import { SPECIAL_PROMO } from '/services/static/attribute-keys';

const getFreeItemPromotionDetails = (lineItems) => {
	const hasFortunePromo = lineItems?.some(
		(item) => item?.attributes[SPECIAL_PROMO] === 'fortune24',
	);

	if (!hasFortunePromo) {
		return undefined;
	}

	const subtotal = lineItems.reduce(
		(sum, item) => sum + parseFloat(item?.estimatedCost?.subtotalAmount?.amount),
		0,
	);

	const specificSockTitles = [
		'The Mystery | Surprise Heel Hammock™ Crew Socks',
		'The Mystery | Surprise Heel Hammock™ Ankle Socks',
	];

	if (subtotal >= 50) {
		const specificSockItem = lineItems?.find((item) =>
			specificSockTitles?.includes(item?.merchandise?.product?.title),
		);

		if (specificSockItem) {
			return specificSockItem?.merchandise?.id;
		}
	}

	if (subtotal >= 75) {
		const mysteryItem = lineItems?.find(
			(item) =>
				item?.merchandise?.product?.title?.includes('Mystery') &&
				!item?.merchandise?.product?.title.includes('Bundle'),
		);

		if (mysteryItem) {
			return mysteryItem?.merchandise?.id;
		}
	}

	return undefined;
};

export default getFreeItemPromotionDetails;
