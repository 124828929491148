import PropTypes from 'prop-types';
import React from 'react';

import { reactChildren } from '/utils/prop-types';

const style = {
	notSelected: ` border-b `,
	selected: ` border-b-2 border-primary text-v2-brown-darker`,
	default: ` text-center -mb-2 px-0 md:px-8 py-4 md:py-1 inline-block focus:outline-none cursor-pointer text-v2-brown`,
};

export function Tabs({ children }) {
	const childrenArray = React.Children.toArray(children);
	const [current, setCurrent] = React.useState(childrenArray[0].key);

	let widthClass = '';
	const numTabs = childrenArray.length;
	if (numTabs === 1) widthClass = ' w-full';
	else if (numTabs === 2) widthClass = ' w-1/2';
	else if (numTabs === 3) widthClass = ' w-1/3';
	else if (numTabs === 4) widthClass = ' w-1/4';
	else if (numTabs === 5) widthClass = ' w-1/5';

	const newChildren = childrenArray.map((child) =>
		React.cloneElement(child, { selected: child?.key === current }),
	);

	return (
		<nav className="max-w-3xl m-auto ">
			{childrenArray.map((child) => (
				<div
					role="link"
					tabIndex={0}
					onClick={() => setCurrent(child?.key)}
					key={child?.key}
					className={`${style.default} ${widthClass}
            ${current === child?.key ? style.selected : style.notSelected}`}>
					{child?.props.title}
				</div>
			))}
			<section>{newChildren}</section>
		</nav>
	);
}

export function Tab({ children, selected }) {
	return (
		<div hidden={!selected} className="mt-4">
			{children}
		</div>
	);
}

Tabs.propTypes = {
	children: reactChildren.isRequired,
};

Tab.propTypes = {
	children: reactChildren.isRequired,
	selected: PropTypes.bool,
};
