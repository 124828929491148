import { getCollectionProducts } from './search';
import { getRecommendedProducts } from './recommendations';
import { getRecommendedSearches } from './suggestedSearch';
import { getSimilarPrints } from './similarPrints';

module.exports = {
	getCollectionProducts,
	getRecommendedProducts,
	getRecommendedSearches,
	getSimilarPrints,
};
