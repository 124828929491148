import Icon from '/components/IconUpdated';
import InputGroup from '/components/InputGroup';
import Link from 'next/link';
import ProductCard from '/components/product-card/ProductCard';
import SimpleProductCard from '/components/product-card/SimpleProductCard';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import debounce from '/utils/debounce';
import searchFooterLinks from '/services/searchspring/footer-links.json';
import { useGlobalStore } from '/state/global-context';
import { useRouter } from 'next/router';
import { getCollectionProducts, getRecommendedSearches } from '/services/searchspring';
import { useCallback, useEffect, useRef, useState } from 'react';

export default function ProductSearch() {
	const globalStore = useGlobalStore();
	const router = useRouter();

	const [products, setProducts] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [showSearch, setShowSearch] = useState(false);
	const inputRef = useRef(null);
	const [top, setTop] = useState(globalStore.topPosition);
	const [suggestedSearches, setSuggestedSearches] = useState([]);
	const [recommendedProducts, setRecommendedProducts] = useState([]);

	const searchProducts = async (val) => {
		if (val.length > 0) {
			const { results } = await getCollectionProducts({
				isCollection: false,
				collectionHandle: null,
				page: 1,
				resultsPerPage: 6,
				q: val,
				filters: {},
			});

			setProducts(results);
		} else {
			setProducts([]);
		}
	};

	const handleSearchChange = useCallback(debounce(searchProducts, 500), []);

	const setSearchVisible = () => {
		setShowSearch(true);
		requestAnimationFrame(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		});
	};

	useEffect(() => {
		setTop(globalStore.topPosition);
	}, [globalStore.topPosition]);

	useEffect(() => {
		const fetchSuggestedSearches = async () => {
			try {
				const response = await getRecommendedSearches();
				setSuggestedSearches(response.data.trending.queries);
			} catch (error) {
				console.error('Error fetching suggested searches:', error);
				setSuggestedSearches([]);
			}
		};

		if (showSearch && searchTerm.length === 0) {
			fetchSuggestedSearches();
		}
	}, [showSearch]);

	useEffect(() => {
		const fetchRecommendedProducts = async () => {
			try {
				const { results } = await getCollectionProducts({
					isCollection: true,
					collectionHandle: 'new-arrivals',
					page: 1,
					resultsPerPage: 8,
					q: '',
					filters: {},
				});
				setRecommendedProducts(results);
			} catch (error) {
				console.error('Error fetching recommended products:', error);
				setRecommendedProducts([]);
			}
		};

		if (showSearch && searchTerm.length === 0) {
			fetchRecommendedProducts();
		}
	}, [showSearch]);

	const submitSearchForm = (event) => {
		event.preventDefault();
		setShowSearch(false);
		router.push({
			pathname: '/search',
			query: { q: searchTerm },
		});
	};

	return (
		<div
			className={classNames(
				'relative transition-[width] duration-300 ease-in-out',
				showSearch ? 'w-60' : 'w-12',
			)}>
			<form onSubmit={submitSearchForm} className="relative z-30" label="search">
				<button
					title="open search input"
					className="absolute top-[13px] left-2 w-6 rounded-full"
					type="button"
					onClick={setSearchVisible}>
					<Icon name="search" height="24px" width="24px" />
				</button>
				{showSearch && (
					<>
						<InputGroup
							ref={inputRef}
							type="text"
							className="w-full border-b-2 border-primary placeholder-primary px-10 py-2 bg-v2-off-white text-base focus:outline-none"
							adaTitle="Search Shinesty"
							placeholder="Ask Jeeves..."
							value={searchTerm}
							onChange={(val) => {
								setSearchTerm(val);
								handleSearchChange(val);
							}}
						/>
					</>
				)}
			</form>

			{showSearch && (
				<div
					className="fixed inset-0 z-20 transition-opacity duration-300 ease-in-out"
					onClick={() => {
						setShowSearch(false);
						setProducts([]);
						setSearchTerm('');
					}}
				/>
			)}

			{showSearch && products.length === 0 && suggestedSearches.length > 0 && (
				<div
					className={`bg-v2-off-white fixed h-[570px] overflow-auto right-0 rounded-sm ${top} w-full z-30 mt-3 shadow-lg
					transform transition-all duration-300 ease-in-out animate-slideIn`}>
					<button
						type="button"
						className="absolute top-2 right-6 text-primary hover:text-primary/80"
						onClick={() => {
							setShowSearch(false);
							setProducts([]);
							setSearchTerm('');
						}}>
						<Icon name="close" height="24px" width="24px" />
					</button>
					<div className="max-w-[1200px] mx-auto w-full p-8">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div>
								<div className="flex items-center gap-2">
									<Typography className="text-left text-v2-brown-darker" variant="subtitle-lg">
										Popular Searches
									</Typography>
								</div>
								<div className="flex flex-col gap-2 divide-y divide-gray-200 pt-4">
									{suggestedSearches.map((suggestion, index) => (
										<div key={index} className={index === 0 ? '' : 'pt-2'}>
											<Link
												href={`/search?q=${encodeURIComponent(suggestion.searchQuery)}`}
												passHref>
												<a
													onClick={() => setShowSearch(false)}
													className="text-v2-brown-darker hover:text-primary block text-left capitalize">
													<div className="flex items-center gap-2">
														<Typography variant="body">{suggestion.searchQuery}</Typography>
														{index === 0 && <Icon name="trending" height="20px" width="20px" />}
													</div>
												</a>
											</Link>
										</div>
									))}
								</div>
							</div>

							<div>
								<Typography className="mb-4 text-left text-v2-brown-darker" variant="subtitle-lg">
									We think you might be looking for
								</Typography>
								<div className="grid grid-cols-4 gap-2">
									{recommendedProducts.map((product, i) => (
										<SimpleProductCard
											key={i}
											product={product}
											onClick={() => setShowSearch(false)}
										/>
									))}
								</div>
							</div>
						</div>

						<div className="mt-4 pt-4 border-t border-gray-200">
							<div className="flex flex-wrap gap-x-6 gap-y-2">
								{searchFooterLinks.footerLinks.map((link, index) => (
									<Link key={index} href={link.href} passHref>
										<a
											className="block text-left hover:underline"
											onClick={() => setShowSearch(false)}>
											<Typography className="text-v2-brown-darker" variant="body">
												{link.title}
											</Typography>
										</a>
									</Link>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
			{showSearch && products.length > 0 && (
				<div
					className={`bg-v2-off-white fixed overflow-auto right-0 rounded-sm ${top} w-full z-30 mt-3 shadow-lg
					transform transition-all duration-300 ease-in-out animate-slideIn`}>
					<button
						type="button"
						className="absolute top-2 right-6 text-primary hover:text-primary/80 z-30"
						onClick={() => {
							setShowSearch(false);
							setProducts([]);
							setSearchTerm('');
						}}>
						<Icon name="close" height="24px" width="24px" />
					</button>
					<div className="mx-auto w-full p-8">
						<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
							{products.map((product, i) => {
								return (
									<ProductCard
										heapLocation="algolia-autocomplete"
										key={i}
										onClick={() => setShowSearch(false)}
										product={product}
										priority={false}
										rank={i}
										showQuickShop={false}
									/>
								);
							})}
						</div>
						<div className="text-center mt-8">
							<Link href={`/search?q=${searchTerm}`}>
								<a onClick={() => setShowSearch(false)}>
									<Typography className="text-primary" component="p" variant="body">
										See All
									</Typography>
								</a>
							</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
