import Link from 'next/link';
import Price from '/components/Price';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getProductType } from '/services/static/product-types';

const SimpleProductCard = ({ product, onClick }) => {
	const productType = getProductType(product.product_type);

	return (
		<Link href={`/products/${product.handle}`}>
			<a onClick={onClick} className="group block">
				<div className="aspect-square overflow-hidden rounded-sm">
					<img
						src={product.imageSrc}
						alt={product.imageAlt}
						className="h-full w-full object-cover group-hover:scale-105 transition-transform duration-200"
					/>
				</div>
				<div className="mt-2 flex flex-col gap-0.5">
					<Typography
						variant="micro"
						className="line-clamp-2 group-hover:text-primary text-v2-brown-darker capitalize">
						{product.title}
					</Typography>
					{productType && (
						<Typography variant="micro" className="text-v2-brown-darker/70">
							{productType.titleShort}
						</Typography>
					)}
					<Typography variant="micro" className="font-bold text-v2-brown-darker">
						<Price price={product.price} showSymbol />
					</Typography>
				</div>
			</a>
		</Link>
	);
};

SimpleProductCard.propTypes = {
	onClick: PropTypes.func,
	product: PropTypes.object,
};

export default SimpleProductCard;
