import { logTryCatch } from '/utils/logging';

import { makeAutoObservable, toJS } from 'mobx';
import { readLocalStorage, removeLocalStorageValue, writeLocalStorage } from '/utils/local-storage';

// if we update these, make sure to update analytics-platform to ingest the data
const VALID_ELEVAR_CLICK_IDS = ['gclid', 'fbclid', 'irclickid', 'ttclid'];

const VALID_ELEVAR_UTMS = [
	'utm_campaign',
	'utm_content',
	'utm_discount',
	'utm_medium',
	'utm_source',
	'utm_term',
];

// if we update these, make sure to update analytics-platform to ingest the data
const VALID_SHINESTY_UTMS = ['discount_code', 'sscid', 'utm_catalog_version', 'shineOn'];

const UTM_HISTORY_KEY = 'utm_history';

const REMAP_PARAMS = {
	discount_code: 'utm_discount',
};

export class UtmStore {
	constructor() {
		makeAutoObservable(this);
	}

	utmState = {};

	getReferrer() {
		return readLocalStorage('referrer');
	}

	getUtmState() {
		return toJS(this.utmState);
	}

	getUtmHistory() {
		return readLocalStorage(UTM_HISTORY_KEY);
	}

	getUtmInfo(query) {
		return Object.keys(query).reduce((result, key) => {
			if (key?.startsWith('utm_')) {
				result[key] = query[key];
			}

			return result;
		}, {});
	}

	getUtmParams(utmType) {
		let utms;

		if (utmType === 'elevar') {
			utms = [...VALID_ELEVAR_CLICK_IDS, ...VALID_ELEVAR_UTMS];
		} else if (utmType === 'shinesty') {
			utms = [...VALID_SHINESTY_UTMS];
		} else {
			utms = [...VALID_ELEVAR_CLICK_IDS, ...VALID_ELEVAR_UTMS, ...VALID_SHINESTY_UTMS];
		}

		return utms.reduce((memo, utmKey) => {
			const value = readLocalStorage(utmKey);

			if (value) {
				memo[utmKey] = value;
			}

			return memo;
		}, {});
	}

	setReferrer(referrer) {
		writeLocalStorage('referrer', referrer);
	}

	initUtmParams(query, referrer, discountCodeStore) {
		this.setReferrer(referrer);

		const queryKeys = Object.keys(query);

		const elevarUtms = [...VALID_ELEVAR_CLICK_IDS, ...VALID_ELEVAR_UTMS];

		const queryIncludesValidUtm = queryKeys.some((utmKey) => {
			return elevarUtms.includes(utmKey);
		});

		const allUtms = [...VALID_ELEVAR_CLICK_IDS, ...VALID_ELEVAR_UTMS, ...VALID_SHINESTY_UTMS];

		if (queryIncludesValidUtm) {
			allUtms.forEach((utmKey) => {
				removeLocalStorageValue(utmKey);
			});
		}

		const validUtms = {};
		queryKeys.forEach((key) => {
			if (allUtms.includes(key)) {
				// discount codes are handled in the discount code service, don't set them in local storage.
				if (!['discount_code', 'utm_discount'].includes(key)) {
					writeLocalStorage(key, query[key]);
				}

				let validValue = query[key];

				if (Array.isArray(query[key])) {
					validValue = query[key][0];
				}

				if (REMAP_PARAMS[key]) {
					validUtms[REMAP_PARAMS[key]] = validValue;
				} else {
					validUtms[key] = validValue;
				}
			}
		});

		if (validUtms?.utm_content && validUtms?.utm_content.includes('MF hook 4 3 reasons')) {
			validUtms.utm_discount = '1799';
		}

		this.updateUtmHistory(validUtms, referrer);

		discountCodeStore.init(validUtms.utm_discount);
	}

	setUtmParams(query) {
		const queryKeys = Object.keys(query);

		const hasValidElevarUtmKey = queryKeys.some((key) => {
			return VALID_ELEVAR_UTMS.includes(key);
		});

		// if any of the new utms on the query string are valid elevar utm parameters,
		// save the current utm parameters to the history and remove the existing
		// utm parameters from storage before saving new utm parameters.
		if (hasValidElevarUtmKey) {
			VALID_ELEVAR_UTMS.forEach((key) => {
				removeLocalStorageValue(key);
			});
		}

		const allUtms = [...VALID_ELEVAR_CLICK_IDS, ...VALID_ELEVAR_UTMS, ...VALID_SHINESTY_UTMS];
		const validUtms = {};

		queryKeys.forEach((key) => {
			if (allUtms.includes(key)) {
				writeLocalStorage(key, query[key]);
				validUtms[key] = query[key];
			}
		});

		this.utmState = validUtms;
	}

	// temporary fix for poor history saving
	cleanUtmHistory(parsed) {
		let lastUtm;
		const validHistory = [];

		for (let i = 0; i < parsed.length; i++) {
			let stringed = JSON.stringify(parsed[i].utms);

			if (lastUtm !== stringed) {
				validHistory.push(parsed[i]);
			}

			lastUtm = stringed;
		}

		return validHistory;
	}

	updateUtmHistory(params, referrer) {
		let parsed = [];

		try {
			let existing = readLocalStorage(UTM_HISTORY_KEY);
			parsed = JSON.parse(existing);
		} catch (e) {
			logTryCatch('could not parse existing utm params');
		}

		if (!parsed || !Array.isArray(parsed)) {
			parsed = [];
		}

		parsed.push({
			createdAt: new Date().toISOString(),
			referrer: referrer || '',
			utms: params,
		});

		parsed = this.cleanUtmHistory(parsed);

		writeLocalStorage(UTM_HISTORY_KEY, JSON.stringify(parsed));
	}

	plain() {
		return toJS(this);
	}
}
