import { getCollectionProducts } from '/services/searchspring/search';

import {
	DEFAULT_PRICE_MENS_BOXER_COOLING,
	DEFAULT_PRICE_MENS_UNDERWEAR,
	DEFAULT_PRICE_WOMENS_THONG,
	DEFAULT_PRICE_WOMENS_THONG_COOLING,
	DEFAULT_PRICE_WOMENS_UNDERWEAR,
} from '/services/static/default-prices';
import {
	DISCOUNTED_LINE_PRICE,
	IS_CHRISTMAS,
	MYSTERY_IMAGE,
	MYSTERY_PACK_ITEM,
	PACK_ID,
} from './static/attribute-keys';
import { SIZES_MENS, SIZES_WOMENS } from '/services/static/sizes';

const COST_BASIS = [1, 1, 1, 0.85, 0.8, 0.75, 0.73, 0.7, 0.67];

const MENS_MYSTERY = 1999;
const WOMENS_MYSTERY = 1119;

const MYSTERY_CONFIGS = [
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-boxer-product-image.jpg',
		priceFull: DEFAULT_PRICE_MENS_UNDERWEAR,
		priceMystery: MENS_MYSTERY,
		productType: 'Boxers',
		sizes: SIZES_MENS,
		title: 'Boxer',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-boxer-product-image.jpg',
		priceFull: DEFAULT_PRICE_MENS_UNDERWEAR,
		priceMystery: MENS_MYSTERY,
		productType: 'Boxers - Fly',
		sizes: SIZES_MENS,
		title: 'Boxer w/ Fly',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-brief-product-image.jpg',
		priceFull: DEFAULT_PRICE_MENS_UNDERWEAR,
		priceMystery: MENS_MYSTERY,
		productType: 'Boxers - Brief',
		sizes: SIZES_MENS,
		title: 'Brief',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-boxer-product-image.jpg',
		priceFull: DEFAULT_PRICE_MENS_BOXER_COOLING,
		priceMystery: 2099,
		productType: 'Boxers - Cooling - Fly',
		sizes: SIZES_MENS,
		title: 'Cooling Boxer',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-boxer-product-image.jpg',
		priceFull: DEFAULT_PRICE_MENS_UNDERWEAR,
		priceMystery: MENS_MYSTERY,
		productType: 'Boxers - Long - Fly',
		sizes: SIZES_MENS,
		title: 'Long Boxer',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-trunk-product-image.jpg',
		priceFull: DEFAULT_PRICE_MENS_UNDERWEAR,
		priceMystery: MENS_MYSTERY,
		productType: 'Boxers - Trunk',
		sizes: SIZES_MENS,
		title: 'Trunks',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-bikini-product-image.jpg',
		priceFull: DEFAULT_PRICE_WOMENS_UNDERWEAR,
		priceMystery: WOMENS_MYSTERY,
		productType: 'Bikini',
		sizes: SIZES_WOMENS,
		title: 'Bikini',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-boyshort-product-image.jpg',
		priceFull: DEFAULT_PRICE_WOMENS_UNDERWEAR,
		priceMystery: WOMENS_MYSTERY,
		productType: 'Boyshort',
		sizes: SIZES_WOMENS,
		title: 'Boyshort',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-cheeky-product-image.jpg',
		priceFull: DEFAULT_PRICE_WOMENS_UNDERWEAR,
		priceMystery: WOMENS_MYSTERY,
		productType: 'Cheeky',
		sizes: SIZES_WOMENS,
		title: 'Cheeky',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-thong-product-image.jpg',
		priceFull: DEFAULT_PRICE_WOMENS_THONG_COOLING,
		priceMystery: 1399,
		productType: 'Thongs - Cooling',
		sizes: SIZES_WOMENS,
		title: 'Cooling Thongs',
	},
	{
		image: 'https://cdn.shinesty.com/2022-09-14/mystery-thong-product-image.jpg',
		priceFull: DEFAULT_PRICE_WOMENS_THONG,
		priceMystery: 979,
		productType: 'Thongs',
		sizes: SIZES_WOMENS,
		title: 'Thongs',
	},
];

const calculatePrice = (productType, quantity) => {
	const mysteryConfig = getMysteryConfigByProductType(productType);

	// if the quantity is higher than the best savings, cart qualifies for highest savings
	const savingsIndex = quantity > COST_BASIS.length ? COST_BASIS.length - 1 : quantity - 1;
	const savings = COST_BASIS[savingsIndex];

	if (!mysteryConfig || typeof savings === 'undefined') {
		return;
	}

	const each = mysteryConfig.priceMystery * savings;
	const total = each * quantity;
	const totalSavings = mysteryConfig.priceFull * quantity - total;

	return {
		each: Math.round(each) / 100,
		eachFull: mysteryConfig.priceFull / 100,
		savings: Math.round(totalSavings) / 100,
		total: Math.round(total) / 100,
	};
};

const getCartLines = async (collectionHandle, preferences, packId) => {
	try {
		const response = await getCollectionProducts({
			isCollection: true,
			collectionHandle,
			resultsPerPage: 48,
			filters: {
				product_type: preferences.productType,
				ss_size: preferences.size,
			},
		});

		if (!response?.results || response.results.length === 0) {
			return { error: 'no products found.' };
		}

		const matchedVariants = [];
		response.results.forEach((product) => {
			product.variants?.forEach((variant) => {
				if (variant.title === preferences.size) {
					matchedVariants.push(variant);
				}
			});
		});

		const variantsResult = getRandomVariants(matchedVariants, preferences.quantity);

		if (variantsResult.isError || variantsResult.variants.length === 0) {
			return { error: 'Could not find mystery products.' };
		}

		const defaultAttrs = [];
		if (preferences.isChristmas) {
			defaultAttrs.push({ key: IS_CHRISTMAS, value: 'true' });
		}

		const generatedLines = Object.keys(variantsResult.variants).reduce((memo, variantId) => {
			memo.push({
				attributes: [
					...defaultAttrs,
					...[
						{ key: MYSTERY_PACK_ITEM, value: 'true' },
						{ key: PACK_ID, value: packId },
						{
							key: DISCOUNTED_LINE_PRICE,
							value: String(preferences.price.each * variantsResult.variants[variantId] || 1),
						},
						{ key: MYSTERY_IMAGE, value: getMysteryImage(preferences.productType) },
					],
				],
				merchandiseId: variantId,
				quantity: variantsResult.variants[variantId] || 1,
			});

			return memo;
		}, []);

		return { generatedLines };
	} catch (error) {
		console.error('Error getting cart lines:', error);
		return { error: 'Failed to generate mystery products.' };
	}
};

const getMysteryConfigByProductType = (productType) => {
	return MYSTERY_CONFIGS.find((config) => {
		return config.productType === productType;
	});
};

const getMysteryImage = (productType) => {
	const mysteryConfig = getMysteryConfigByProductType(productType);
	return mysteryConfig && mysteryConfig.image;
};

const getConfigs = async (collectionHandle) => {
	try {
		const response = await getCollectionProducts({
			isCollection: false,
			collectionHandle,
			resultsPerPage: 20,
			facets: true,
		});

		if (!response?.facets) {
			return [];
		}

		const productTypeFacet = response.facets.find((facet) => facet.key === 'product_type');

		if (!productTypeFacet?.facets) {
			return [];
		}

		const validProductTypes = productTypeFacet.facets.map((value) => value.title);

		const validConfigs = MYSTERY_CONFIGS.reduce((memo, mysteryConfig) => {
			if (validProductTypes.includes(mysteryConfig.productType)) {
				memo.push(mysteryConfig);
			}
			return memo;
		}, []);
		return validConfigs;
	} catch (error) {
		console.error('Error getting configs:', error);
		return [];
	}
};

const getRandomVariants = (hits, neededQuantity) => {
	hits = hits.filter((hit) => {
		return hit.inventory > 0;
	});

	const hitsLength = hits.length;

	if (hits.length === 0) {
		return { error: 'no hits', isError: true };
	}

	const variants = {};
	let includeDuplicates = false;
	let safety = 100;
	let quantityCount = 0;

	if (neededQuantity >= hitsLength) {
		hits.forEach((hit) => {
			variants[hit.shopifyVariantId] = 1;
			quantityCount++;
		});

		includeDuplicates = true;
	}

	while (quantityCount < neededQuantity) {
		const newIndex = Math.floor(Math.random() * hitsLength);
		// avoid duplicates if we can
		const hit = hits[newIndex];

		if (
			hit.shopifyVariantId &&
			((includeDuplicates !== true && !variants[hit.shopifyVariantId]) ||
				includeDuplicates === true)
		) {
			if (variants[hit.shopifyVariantId]) {
				variants[hit.shopifyVariantId]++;
			} else {
				variants[hit.shopifyVariantId] = 1;
			}

			quantityCount++;
		}

		safety--;
		if (safety === 0) {
			includeDuplicates = true;
		}
	}

	return { isError: false, variants };
};

const getSizes = async (collectionHandle, productType, quantityMinimum) => {
	try {
		const response = await getCollectionProducts({
			isCollection: true,
			collectionHandle,
			resultsPerPage: 0,
			facets: true,
			filters: {
				product_type: productType,
			},
		});

		const sizesFacet = response.facets.find((facet) => facet.key === 'ss_size');
		console.log('sizesFacet', sizesFacet);
		if (!sizesFacet?.facets) {
			return [];
		}

		const availableSizes = sizesFacet.facets.reduce((acc, value) => {
			acc[value.title] = value.count;
			return acc;
		}, {});

		const mysteryConfig = getMysteryConfigByProductType(productType);
		if (!mysteryConfig) {
			return [];
		}

		const validSizes = mysteryConfig.sizes.map((size) => {
			const variantsAvailable = availableSizes[size] || 0;
			return {
				isAvailable: variantsAvailable >= quantityMinimum,
				title: size,
				variantsAvailable,
			};
		});

		return validSizes;
	} catch (error) {
		console.error('Error getting sizes:', error);
		return [];
	}
};

module.exports = {
	calculatePrice,
	getCartLines,
	getConfigs,
	getMysteryImage,
	getSizes,
};
