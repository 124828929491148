/* You'll need to install @reach/portal which simplify creating portal*/
import * as Portal from '@radix-ui/react-portal';

import PropTypes from 'prop-types';
import classNames from '/utils/class-names';
import { reactChildren } from '/utils/prop-types';

import { useEffect, useRef } from 'react';

const style = {
	body: `flex-shrink flex-grow md:rounded-md`,
	headerTitle: `text-2xl md:text-3xl font-light`,
	header: `items-start justify-between p-4 border-b border-gray-300`,
	container: `fixed top-0 overflow-y-auto left-0 z-[2147483005] w-full h-full m-0`,
	overlay: `fixed top-0 left-0 z-40 w-screen h-screen bg-black opacity-50 z-[2147483003]`,
	content: `animate-modal relative flex flex-col pointer-events-auto rounded-md max-w-3xl md:my-4 m-auto focus:outline-none`,
	footer: `flex flex-wrap items-center justify-end p-3 border-t border-gray-300`,
	orientation: `mt-6 pb-4 mx-auto md:w-9/12 lg:w-7/12 focus:outline-none`,
};

export default function Modal({ children, isOpen, size, toggle, customType, locked }) {
	const ref = useRef();
	const container = useRef();

	let contentStyles = style.content;
	let orientationStyles = style.content;

	// TODO: make this more generic
	if (customType == 'stacking' || customType === 'conjured') {
		contentStyles = `animate-modal relative flex flex-col pointer-events-auto rounded-md max-w-3xl m-auto`;
		orientationStyles = `mx-auto md:w-9/12 lg:w-7/12 focus:outline-none`;
	}
	if (size == 'large') {
		orientationStyles = `mx-auto md:w-12/12 lg:w-12/12 focus:outline-none`;
	}

	if (customType === 'conjured') {
		orientationStyles += ' md:mt-16';
	}

	// close modal on click outside
	useEffect(() => {
		if (locked) {
			return;
		}

		const handleOutsideClick = (event) => {
			if (!ref.current?.contains(event.target)) {
				if (!isOpen) return;
				toggle(false);
			}
		};

		container.current?.addEventListener('click', handleOutsideClick);
		return () => container.current?.removeEventListener('click', handleOutsideClick);
	}, [isOpen, ref, container, toggle]);

	// close modal when you click on "ESC" key
	useEffect(() => {
		const handleEscape = (event) => {
			if (!isOpen) {
				return;
			}

			if (event.key === 'Escape') {
				toggle(false);
			}
		};

		document.addEventListener('keyup', handleEscape);

		return () => document.removeEventListener('keyup', handleEscape);
	}, [isOpen, toggle]);

	// hide scrollbar and prevent body from moving when modal is open
	//put focus on modal dialogue
	useEffect(() => {
		if (!isOpen) {
			return;
		}

		ref.current?.focus();

		const html = document.documentElement;
		const scrollbarWidth = window.innerWidth - html.clientWidth;

		html.style.overflow = 'hidden';
		html.style.paddingRight = `${scrollbarWidth}px`;

		return () => {
			html.style.overflow = '';
			html.style.paddingRight = '';
		};
	}, [isOpen]);

	return (
		<Portal.Root>
			{isOpen && (
				<>
					<div className={style.overlay} />
					<div ref={container} className={style.container}>
						<div
							aria-modal={true}
							className={orientationStyles}
							ref={ref}
							role="dialogue"
							tabIndex={-1}>
							<div className={contentStyles}>{children}</div>
						</div>
					</div>
				</>
			)}
		</Portal.Root>
	);
}

export function ModalHeader({ children }) {
	return (
		<div className={style.header}>
			<h4 className={style.headerTitle}>{children}</h4>
		</div>
	);
}

export function ModalBody({ children, className }) {
	return <div className={classNames(style.body, className)}>{children}</div>;
}

export function ModalFooter({ children }) {
	return <div className={style.footer}>{children}</div>;
}

Modal.propTypes = {
	children: reactChildren.isRequired,
	className: PropTypes.string,
	customType: PropTypes.string,
	isOpen: PropTypes.bool,
	locked: PropTypes.bool,
	size: PropTypes.string,
	toggle: PropTypes.func,
};

ModalHeader.propTypes = {
	children: reactChildren.isRequired,
};

ModalBody.propTypes = {
	children: reactChildren.isRequired,
	className: PropTypes.string,
};

ModalFooter.propTypes = {
	children: reactChildren.isRequired,
};
