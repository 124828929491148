import axios from 'axios';
const getRemoteNavigationConfig = async () => {
	return axios
		.get(`${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/navigation-current`)
		.then((res) => {
			if (res?.status === 200 && res.data) {
				return res.data;
			}
		})
		.catch(() => {
			return {
				menus: [],
			};
		});
};

const getNavigationConfigById = async () => {
	return axios
		.get(`${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/navigation-by-id/67d9d4e7326793325568eaa6`)
		.then((res) => {
			if (res?.status === 200 && res.data) {
				return res.data;
			}
		})
		.catch(() => {
			return {
				menus: [],
			};
		});
};

module.exports = {
	getNavigationConfigById,
	getRemoteNavigationConfig,
};
