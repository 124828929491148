import axios from 'axios';

export const getRecommendedSearches = async () => {
	const baseUrl = `https://${process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID}.a.searchspring.io/api/suggest/trending`;
	const params = new URLSearchParams();
	params.append('siteId', process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID);
	params.append('siteId', 10);

	try {
		return axios.get(`${baseUrl}?${params.toString()}`);
	} catch (error) {
		console.error('Searchspring API Error:', error);
		throw error;
	}
};
