import Icon from '/components/IconUpdated';
import PropTypes from 'prop-types';
import classNames from '/utils/class-names';
import { reactChildren } from '/utils/prop-types';
import { createContext, useCallback, useContext, useRef, useState } from 'react';

const Context = createContext({});
const useAccordion = () => useContext(Context);

export function Accordion({ children }) {
	const [openPanels, setOpenPanels] = useState([]);

	const toggleItem = useCallback(
		(id) => () => {
			setOpenPanels((prevState) =>
				prevState.includes(id) ? prevState.filter((x) => x !== id) : [...prevState, id],
			);
		},
		[],
	);

	return <Context.Provider value={{ openPanels, toggleItem }}>{children}</Context.Provider>;
}

//custom hook to consume all accordion values

export function AccordionItem({ toggle, children, variant }) {
	const { openPanels, toggleItem } = useAccordion();

	const caretSize = variant === 'mobile-nav' ? '10px' : '14px';

	return (
		<button
			aria-controls={toggle}
			aria-expanded={openPanels.includes(toggle)}
			type="button"
			onClick={toggleItem(toggle)}
			className={classNames(
				'flex items-center justify-between p-3 w-full',
				variant === 'mobile-nav' ? '' : '',
			)}>
			<div className="text-left">{children}</div>
			<div>
				{openPanels.includes(toggle) ? (
					<Icon name="caretUp" height={caretSize} width={caretSize} />
				) : (
					<Icon name="caretDown" height={caretSize} width={caretSize} />
				)}
			</div>
		</button>
	);
}

export function AccordionPanel({ children, id }) {
	const { openPanels } = useAccordion();
	const ref = useRef();
	const inlineStyle = openPanels.includes(id)
		? { height: ref.current?.scrollHeight }
		: { height: 0, visibility: 'hidden' };

	return (
		<div
			ref={ref}
			id={id}
			className="md:overflow-x-hidden transition-height ease duration-300 text-gray-600 overflow-scroll scrollbar-hide"
			style={inlineStyle}>
			{children}
		</div>
	);
}
Accordion.propTypes = {
	children: reactChildren.isRequired,
};

AccordionItem.propTypes = {
	children: reactChildren,
	toggle: PropTypes.string,
	variant: PropTypes.string,
};

AccordionPanel.propTypes = {
	children: reactChildren,
	id: PropTypes.string,
};
