import MeasurementTable from './MeasurementTable';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import classNames from '/utils/class-names';

import { useEffect, useState } from 'react';

const MeasurementTables = ({ note, tables }) => {
	const [filteredTables, setFilteredTables] = useState([]);
	const [selectedUnit, setSelectedUnit] = useState('in');

	useEffect(() => {
		filterTablesByUnit('in');
	}, [tables]);

	const filterTablesByUnit = (unit) => {
		setSelectedUnit(unit);

		const tableType = unit === 'in' ? 'default' : 'international';

		const filtered = tables ? tables.filter((table) => table.key === tableType) : [];
		setFilteredTables(filtered);
	};

	if (!filteredTables || filteredTables.length === 0) {
		return null;
	}

	return (
		<div className="w-full text-center px-4 md:pt-4 pt-2">
			{note && (
				<Typography component="p" variant="body" className="md:pb-4 max-w-3xl m-auto md:px-10">
					{note}
				</Typography>
			)}

			<div className="flex justify-center my-4">
				<div className="inline-flex rounded-full overflow-hidden border border-gray-300 bg-v2-cream">
					<button
						className={classNames(
							'px-6 py-2 text-sm font-medium',
							selectedUnit === 'in'
								? 'bg-v2-off-white border-v2-brown-darker border-2 rounded-full'
								: 'bg-v2-cream',
						)}
						onClick={() => filterTablesByUnit('in')}>
						in
					</button>
					<button
						className={classNames(
							'px-6 py-2 text-sm font-medium',
							selectedUnit === 'cm'
								? 'bg-v2-off-white border-v2-brown-darker border-2 rounded-full'
								: 'bg-v2-cream',
						)}
						onClick={() => filterTablesByUnit('cm')}>
						cm
					</button>
				</div>
			</div>

			{filteredTables &&
				filteredTables.map((table, i) => {
					return <MeasurementTable key={i} tableDetails={table.measurementTable} />;
				})}
		</div>
	);
};

MeasurementTables.propTypes = {
	note: PropTypes.string,
	tables: PropTypes.array,
};

export default MeasurementTables;
