import { getNavigationConfigById } from '/services/navigation';
import { getTestVariant } from '/services/ab-test';
import { checkFunctionality, getCountryByCode } from '/services/static/countries';
import { makeAutoObservable, toJS } from 'mobx';

/** if you update any of these positions, you need to
 * update the hidden elements in main layout prevent tailwind
 * from removing the classes when it purges our css.
 */
const TOP_POSITIONS_DEFUALT_FACETS = 'top-52';
const TOP_POSITIONS_DEFAULT_PADDING_MAIN = 'pt-24 lg:pt-32';
const TOP_POSITIONS_DEFAULT_PAGE = 'top-32';
const TOP_POSITIONS_DEFAULT_PAGE_MOBILE = 'top-24';
const TOP_POSITIONS_NO_BANNER_FACETS = 'top-40';
const TOP_POSITIONS_NO_BANNER_PADDING_MAIN = 'pt-14 lg:pt-20';
const TOP_POSITIONS_NO_BANNER_PAGE = 'top-20';
const TOP_POSITIONS_NO_BANNER_PAGE_MOBILE = 'top-14';
const TOP_POSITIONS_PROMOTION_FACETS = 'top-52';
const TOP_POSITIONS_PROMOTION_PADDING_MAIN = 'pt-[120px] lg:pt-32';
const TOP_POSITIONS_PROMOTION_PAGE = 'top-32';
const TOP_POSITIONS_PROMOTION_PAGE_MOBILE = 'top-14';

export class GlobalStore {
	cookieConsent = true;
	currentCountry = getCountryByCode('US');

	isBannerHidden = false;

	isPageScrollDisabled = false;

	showLoadingBar = false;
	sitewidePackBuilder = false;
	state = '';

	topPositionFacets = TOP_POSITIONS_DEFUALT_FACETS;
	topPositionPaddingMain = TOP_POSITIONS_DEFAULT_PADDING_MAIN;
	topPositionPage = TOP_POSITIONS_DEFAULT_PAGE;
	topPositionPageMobile = TOP_POSITIONS_DEFAULT_PAGE_MOBILE;

	pathCurrent = '';
	pathPrevious = '';

	navigationConfig = {};
	promotionalConfig = {};

	constructor() {
		makeAutoObservable(this);
	}

	getCurrentCountry() {
		return this.currentCountry;
	}

	getCurrentCountryName() {
		return toJS(this.currentCountry.name);
	}

	getCurrentState() {
		return toJS(this.state);
	}

	async setCurrentCountryByCode(countryCode, cart) {
		// no country passed, default to US
		if (!countryCode) {
			countryCode = 'US';
		}

		let country = getCountryByCode(countryCode);

		// invalid country returned, default to US
		if (!country) {
			country = getCountryByCode('US');
		}

		this.currentCountry = country;

		const countryHasSubscriptions = checkFunctionality(country.countryCode, 'subscription');
		if (!countryHasSubscriptions) {
			await cart.removeSubscriptions();
		}
	}

	setCookieConsent(consent) {
		this.cookieConsent = consent;
	}

	setCurrentPage(path) {
		this.pathPrevious = this.pathCurrent;
		this.pathCurrent = path;
	}

	setDisablePageScroll(isDisabled) {
		this.isPageScrollDisabled = isDisabled;

		if (this.isPageScrollDisabled) {
			document.body.classList.add('overflow-hidden');
		} else {
			document.body.classList.remove('overflow-hidden');
		}
	}

	getNavigationConfig() {
		return toJS(this.navigationConfig);
	}

	async setNavigationConfig(config) {
		try {
			const test = await getTestVariant('navigation-2025-03-18');
			if (test?.key === 'new-nav') {
				const newConfig = await getNavigationConfigById();
				this.navigationConfig = newConfig;
			} else {
				this.navigationConfig = config;
			}
		} catch (error) {
			this.navigationConfig = config;
		}
	}

	setPromotionalConfig(config) {
		this.promotionalConfig = config;
		if (config?.dateEnd) {
			this.topPositionFacets = TOP_POSITIONS_PROMOTION_FACETS;
			this.topPositionPaddingMain = TOP_POSITIONS_PROMOTION_PADDING_MAIN;
			this.topPositionPage = TOP_POSITIONS_PROMOTION_PAGE;
			this.topPositionPageMobile = TOP_POSITIONS_PROMOTION_PAGE_MOBILE;
		}
	}

	getCookieConsent() {
		return toJS(this.cookieConsent);
	}

	getPromotionalConfig() {
		return toJS(this.promotionalConfig);
	}

	getSitewidePack() {
		return toJS(this.sitewidePackBuilder);
	}

	setSitewidePack(status) {
		this.sitewidePackBuilder = status;
	}

	setState(state) {
		this.state = state;
	}

	setBannerIsHidden(isHidden) {
		this.isBannerHidden = isHidden;

		if (this.isBannerHidden) {
			this.topPositionFacets = TOP_POSITIONS_NO_BANNER_FACETS;
			this.topPositionPaddingMain = TOP_POSITIONS_NO_BANNER_PADDING_MAIN;
			this.topPositionPage = TOP_POSITIONS_NO_BANNER_PAGE;
			this.topPositionPageMobile = TOP_POSITIONS_NO_BANNER_PAGE_MOBILE;
		} else {
			this.topPositionFacets = TOP_POSITIONS_DEFUALT_FACETS;
			this.topPositionPaddingMain = TOP_POSITIONS_DEFAULT_PADDING_MAIN;
			this.topPositionPage = TOP_POSITIONS_DEFAULT_PAGE;
			this.topPositionPageMobile = TOP_POSITIONS_DEFAULT_PAGE_MOBILE;
		}
	}

	toggleLoadingBar(show) {
		this.showLoadingBar = show;
	}

	plain() {
		return toJS(this);
	}
}
