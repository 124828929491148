import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getButtonImageSrc } from '/services/products';
import { getSimilarPrints } from '/services/searchspring/similarPrints';
import {
	PRODUCT_TYPE_BOYS_UNDERWEAR_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_HOODIE_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_JOGGER_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_LOWER,
	PRODUCT_TYPE_T_SHIRTS_LOWER,
} from '/services/static/product-types';
import { useEffect, useRef, useState } from 'react';

export default function ProductCardPrintSelect({ heapLocation, product, updateProduct }) {
	const [printOptions, setPrintOptions] = useState([]);
	const [activePrint, setActivePrint] = useState();
	const [isVisible, setIsVisible] = useState(false);
	const componentRef = useRef(null);
	const hasFetched = useRef(false);
	const noFlavorProductTypes = [
		PRODUCT_TYPE_BOYS_UNDERWEAR_LOWER,
		PRODUCT_TYPE_LOUNGE_MENS_HOODIE_LOWER,
		PRODUCT_TYPE_LOUNGE_MENS_JOGGER_LOWER,
		PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_LOWER,
		PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_LOWER,
		PRODUCT_TYPE_T_SHIRTS_LOWER,
	];
	useEffect(() => {
		let print = product.tags.find((tag) => tag.includes('print'));
		if (print) {
			const [first, ...rest] = print.split(':');
			const capitalizedRest = rest
				.join(':')
				.split(' ')
				.map((word, index) => (index > 0 ? word?.charAt(0)?.toUpperCase() + word.slice(1) : word))
				.join('');
			setActivePrint(`${first}: ${capitalizedRest}`);
		}
	}, [product.handle]);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting);
			},
			{ threshold: 0.1 },
		);

		if (componentRef.current) {
			observer.observe(componentRef.current);
		}

		return () => {
			if (componentRef.current) {
				observer.unobserve(componentRef.current);
			}
		};
	}, [product.handle]);

	useEffect(() => {
		hasFetched.current = false;
		setPrintOptions([product]);
	}, [product.handle]);

	useEffect(() => {
		async function getPrints() {
			try {
				if (noFlavorProductTypes.includes(product.shopifyProductType)) {
					setPrintOptions([product]);
					return;
				}

				if (!isVisible || hasFetched.current) {
					return;
				}

				hasFetched.current = true;
				const similarPrints = await getSimilarPrints(
					product,
					activePrint,
					product.selectedVariant?.size,
				);

				const allPrints = [product];
				similarPrints.forEach((print) => {
					if (print.id !== product.id) {
						allPrints.push(print);
					}
				});

				setPrintOptions(allPrints);
			} catch (error) {
				console.error('Error fetching similar prints:', error);
				setPrintOptions([product]);
			}
		}

		if (activePrint || noFlavorProductTypes.includes(product.shopifyProductType)) {
			getPrints();
		}
	}, [activePrint, product, isVisible]);

	const limit = 5;
	let count = 0;

	if (
		heapLocation == 'algolia-autocomplete' ||
		product?.handle?.includes('pack') ||
		product?.handle?.includes('subscription')
	) {
		return null;
	}

	return (
		<div ref={componentRef} className="flex w-full gap-2 pb-1 pt-2">
			{printOptions.length > 1 &&
				printOptions.map((print, i) => {
					if (print.handle === printOptions[0].handle && i > 0) {
						return null;
					}

					const buttonImage = getButtonImageSrc(print);
					if (buttonImage && count < limit) {
						count++;
						return (
							<Clickable
								key={print.id}
								onClick={() => updateProduct(print)}
								type="button"
								className={classNames(
									'rounded-full h-[22px] w-[22px] lg:h-[32px] lg:w-[32px] hover:border-2 border-white hover:ring-gray-600 hover:ring-4',
									product.handle === print.handle
										? 'ring-gray-600 ring-4 border-white border-2'
										: '',
									count > 3 ? 'hidden lg:block' : '',
								)}>
								<Image
									src={buttonImage}
									height={200}
									width={200}
									objectFit="contain"
									alt={`${print.title} button image`}
									className="rounded-full"
								/>
							</Clickable>
						);
					}
					return null;
				})}
			{printOptions.length > 2 && (
				<div className="flex items-center justify-center">
					<Clickable linkToInternal={'/products/' + product.handle}>
						<Typography className="font-semibold text-secondary-dark" variant="body">
							+{printOptions.length}
						</Typography>
					</Clickable>
				</div>
			)}
		</div>
	);
}

ProductCardPrintSelect.propTypes = {
	heapLocation: PropTypes.string,
	product: PropTypes.object.isRequired,
	updateProduct: PropTypes.func.isRequired,
};
