import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { reactChildren } from '/utils/prop-types';

const Button = forwardRef(
	(
		{
			children,
			className = '',
			color = 'primary',
			size = 'base',
			variant = 'filled',
			submit = false,
			full = false,
			disabled = false,
			...rest
		},
		ref,
	) => {
		let finalClass = '';

		if (variant === 'none') finalClass += '';
		else if (variant === 'link') finalClass += 'text-base';
		else if (variant === 'hero')
			finalClass += 'text-sm py-2 px-2 md:text-l lg:text-xl md:px-10 md:font-bold tracking-widest';
		else if (size === 'xs') finalClass += 'text-xs py-1 px-2';
		else if (size === 'sm') finalClass += 'text-sm py-2 px-4';
		else if (size === 'base') finalClass += 'text-base py-3 px-6 font-bold tracking-widest';
		else if (size === 'lg') finalClass += 'text-lg py-3 px-8 font-bold tracking-widest';
		else if (size === 'xl') finalClass += 'text-xl py-3 px-12 font-bold tracking-widest';

		// tailwind can't use string interpolation because of the production build purges any classes that aren't used.
		if (variant === 'filled' || variant === 'hero') {
			finalClass += ` text-${color}-content bg-${color} rounded-sm  rounded-md`;
			if (!disabled) {
				finalClass += ` hover:bg-${color}-dark shadow-slate-900  focus:outline-0 active:bg-primary-darker`;
			}
		} else if (variant === 'filled-gray') {
			finalClass += ` text-${color} bg-gray-200 rounded-sm`;
			if (!disabled) {
				finalClass += ` hover:bg-${color} hover:bg-gray-300  focus:outline-0 active:bg-gray-200`;
			}
		} else if (variant === 'outlined') {
			finalClass += ` text-${color} bg-v2-off-white border-${color} border-2 rounded-md`;
			if (!disabled) {
				finalClass += ` hover:bg-${color} hover:border-white hover:text-white  focus:outline-0 active:bg-primary/30`;
			}
		} else if (variant === 'naked') {
			finalClass += ` text-${color} border-0 bg-transparent rounded-sm  focus:outline-0 active:bg-v2-off-white`;
			if (!disabled) {
				finalClass += ` hover:text-${color}-dark`;
			}
		} else if (variant === 'link') {
			finalClass += ` text-${color} border-0 bg-transparent px-0 hover:underline rounded-sm`;
			if (!disabled) {
				finalClass += ` hover:text-${color}-dark`;
			}
		} else if (variant === 'round') {
			finalClass += ` rounded-full text-${color}-content bg-${color}`;
			if (!disabled) {
				finalClass += ` hover:bg-${color}-dark shadow-slate-900  focus:scale-[101%] focus:outline-0 active:text-primary-darker`;
			}
		} else if (variant === 'round-outlined') {
			finalClass += ` bg-v2-off-white border-${color} border-[3px] px-2 rounded-xl text-${color}`;
			if (!disabled) {
				finalClass += ` hover:bg-gray-200  focus:scale-[101%] focus:outline-0 active:text-primary-darker`;
			}
		} else if (variant === 'round-filled') {
			finalClass += ` text-${color}-content bg-${color} px-2 rounded-full`;
			if (!disabled) {
				finalClass += ` hover:bg-${color}-dark  focus:scale-[101%] focus:outline-0 active:text-primary-darker`;
			}
		}

		if (full) finalClass += ' w-full';
		if (disabled) finalClass += ' opacity-50 cursor-not-allowed';
		if (variant !== 'hero') finalClass += ' ';
		finalClass += ` inline-block text-center transition-all duration-200 ${className} `;

		return (
			<>
				{/* These funny hidden divs are here because Tailwind will purge any class that isn't used in a className attr. 
				Since these are interpolated above they aren't actually in a className */}
				{false && (
					<>
						<div className=" rounded-full text-primary bg-primary border-primary hover:bg-primary-dark hover:text-primary-content hover:border-white" />
						<div className=" text-secondary bg-secondary hover:text-secondary-content hover:bg-secondary-dark" />
						<div className="hover:bg-primary bg-tertiary hover:bg-tertiary-dark text-tertiary-content" />
					</>
				)}
				<button
					ref={ref}
					className={finalClass}
					type={submit ? 'submit' : 'button'}
					{...rest}
					disabled={disabled}>
					{children}
				</button>
			</>
		);
	},
);

Button.displayName = 'universal button';

Button.propTypes = {
	children: reactChildren.isRequired,
	className: PropTypes.string,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	full: PropTypes.bool,
	size: PropTypes.oneOf(['xs', 'sm', 'base', 'lg', 'xl']),
	submit: PropTypes.bool,
	variant: PropTypes.oneOf([
		'filled',
		'filled-gray',
		'hero',
		'link',
		'naked',
		'none',
		'outlined',
		'round',
		'round-outlined',
		'round-filled',
	]),
};

export default Button;
