import { createGid } from '/utils/graph-ql';

const decodeHtmlEntities = (text) => {
	if (!text) return '';
	return text
		.replace(/&amp;/g, '&')
		.replace(/&quot;/g, '"')
		.replace(/&#039;/g, "'")
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>');
};

const parseVariants = (variantsString) => {
	if (!variantsString) return [];
	try {
		const decodedString = decodeHtmlEntities(variantsString);
		const parsedVariants = JSON.parse(decodedString);

		return parsedVariants.map((variant) => ({
			...variant,
			title: variant.title,
			price: parseFloat(variant.price),
			compare_at_price: parseFloat(variant.compare_at_price),
			inventory: variant.inventory_quantity,
			sku: variant.sku,
			shopifyVariantId: variant.admin_graphql_api_id,
		}));
	} catch (error) {
		console.error('Error parsing variants:', error);
		return [];
	}
};

export const formatSearchspringProduct = (product) => {
	const decodedName = decodeHtmlEntities(product.name);
	const titles = decodedName.split(' | ');
	const title = titles.length > 1 ? titles[0] : decodedName;
	const description = titles.length > 1 ? titles[1] : '';
	const price = parseFloat(product.price);
	const retailPrice = parseFloat(product.msrp);
	const variants = parseVariants(product.variants);

	return {
		handle: product.url.split('/products/')[1],
		buttonImage: product?.button_image || null,
		shopifyProductId: createGid('Product', product.uid, true),
		title,
		description,
		id: product.uid,
		imageSrc: product.image,
		imageAlt: product.name,
		images: [
			{
				altText: product.name,
				height: 2000,
				width: 2000,
				imageSrc: product.image,
			},
		],
		price,
		priceFull: retailPrice,
		pricing: {
			min: price,
			max: price,
			retailMin: retailPrice,
			retailMax: retailPrice,
		},
		product_type: product.product_type,
		shopifyProductType: product.product_type.toLowerCase(),
		rating: parseFloat(product.rating),
		ratingCount: parseInt(product.ratingCount),
		tags: product.tags ? product.tags.map((tag) => tag.toLowerCase()) : [],
		variants,
		international_pricing: {
			price,
			compare_at_price: retailPrice,
		},
	};
};

export const formatSearchspringFacets = (facets, selectedFacets = {}) => {
	return facets.map((facet) => ({
		key: facet.field,
		title: facet.label,
		type: 'list',
		facets: facet?.values?.map((value) => ({
			title: value.label || null,
			count: value.count || 0,
			isSelected: selectedFacets[facet.field]?.includes(value.label) || false,
		})),
	}));
};

export const formatSearchspringResponse = (response, selectedFacets = {}) => {
	return {
		results: response.results.map((product) => formatSearchspringProduct(product)),
		pagination: {
			totalResults: response.pagination.totalResults,
			totalPages: response.pagination.totalPages,
			currentPage: response.pagination.currentPage,
			perPage: response.pagination.perPage,
			begin: response.pagination.begin,
			end: response.pagination.end,
		},
		facets: response.facets ? formatSearchspringFacets(response.facets, selectedFacets) : [],
	};
};

export const formatRecommendationsResponse = (response) => {
	const recommendationSet = response[0];

	return {
		results: recommendationSet.results.map((item) => {
			const product = item.mappings.core;
			const titles = product.name.split(' | ');
			const title = titles.length > 1 ? titles[0] : product.name;
			const description = titles.length > 1 ? titles[1] : '';
			const price = parseFloat(product.price);
			const retailPrice = parseFloat(product.msrp);

			return {
				handle: product.url.split('/products/')[1],
				shopifyProductId: createGid('Product', product.uid, true),
				title,
				description,
				id: product.uid,
				imageSrc: product.imageUrl,
				imageAlt: product.name,
				images: [
					{
						altText: product.name,
						height: 2000,
						width: 2000,
						imageSrc: product.imageUrl,
					},
				],
				price,
				priceFull: retailPrice,
				pricing: {
					min: price,
					max: price,
					retailMin: retailPrice,
					retailMax: retailPrice,
				},
				product_type: product.product_type || '',
				shopifyProductType: (product.product_type || '').toLowerCase(),
				rating: parseFloat(product.rating),
				ratingCount: parseInt(product.ratingCount),
				tags: [],
				international_pricing: {
					price,
					compare_at_price: retailPrice,
				},
			};
		}),
		pagination: {
			totalResults: recommendationSet.results.length,
			totalPages: 1,
			currentPage: 1,
			perPage: recommendationSet.results.length,
			begin: 0,
			end: recommendationSet.results.length,
		},
		facets: [],
	};
};
