import PropTypes from 'prop-types';
import { reactChildren } from '/utils/prop-types';

const defaultClassMapping = {
	body: 'leading-[1.40rem] tracking-wider', //keep - ks
	'body-lg': 'text-lg leading-tighter tracking-wider', //not sure - ks
	'body-heavy': 'text-base leading-snug tracking-wider font-bold', //might need - ks
	link: 'tracking-wider text-secondary underline underline-offset-2 hover:text-secondary-dark', //maybe remove color - ks
	'link-header': 'tracking-wider text-black hover:text-primary font-bold capitalize',
	'link-red': 'tracking-wider hover:text-primary', //default text color is set globally; this allows base color to be any
	'link-dark': 'tracking-wider hover:text-tertiary hover:underline hover:underline-offset-2',
	micro: 'text-xs font-medium tracking-wider leading-tight',
	pretitle: 'font-bold capitalize text-sm tracking-wider', //maybe doesn't need the capitalize, might need device styles - ks
	small: 'font-medium leading-4 text-sm tracking-wide', //keep - ks
	big: 'md:text-4xl text-[20px] md:leading-8 leading-6 font-regular tracking-wide', //do we really need this? - ks
	preheading: 'font-bold leading-3 text-sm tracking-wider lg:leading-4 lg:text-base',
	'heading-xl':
		'font-bold leading-8 capitalize text-3xl lg:text-[3.5rem] lg:leading-[2.5rem] tracking-widest',
	'heading-lg':
		'font-bold leading-8 capitalize text-3xl lg:text-4xl lg:leading-[2.5rem] tracking-wide',
	'heading-md': 'font-bold leading-5 capitalize text-lg tracking-widest lg:text-2xl lg:leading-7',
	'heading-sm': 'font-bold leading-6 md:leading-5 capitalize text-md md:text-lg tracking-widest', //formerly h4
	'heading-xs': 'font-bold leading-4 capitalize tracking-widest', //mob and desktop same
	'subhead-lg': 'font-bold leading-6 capitalize text-2xl lg:leading-10 lg:text-4xl', //True h2
	'subhead-md': 'font-bold  leading-7 capitalize tracking-wide text-[28px]', //mob and desktop same
	'subhead-sm': 'font-bold  leading-5 capitalize text-lg ', //mob and desktop same
	'subhead-xs': 'font-bold  leading-4 capitalize', //mob and desktop same
	'subtitle-lg': 'font-semibold leading-5 text-lg lg:leading-6 lg:text-xl', //true subtitle/h3
	'subtitle-md': 'font-semibold leading-[1.40rem] md:leading-5 md:text-lg',
	'subtitle-sm': 'font-semibold leading-[1.40rem]', //not sure if this is over engineering body
};

const Typography = ({ children, font = 'lexend', variant, component, className, ...other }) => {
	// catch for dynamic data not rendering a component
	if (!children) {
		return null;
	}

	const fonts = {
		moret: 'font-moret',
		lexend: 'font-lexend',
	};

	let variantClasses = defaultClassMapping[variant] || '';

	const Component = component || 'span';

	if (className) {
		variantClasses += ` ${className}`;
	}

	variantClasses += ` ${fonts[font]}`;

	return (
		<Component className={variantClasses} {...other}>
			{children}
		</Component>
	);
};

Typography.propTypes = {
	children: reactChildren,
	className: PropTypes.string,
	component: PropTypes.string,
	font: PropTypes.string,
	variant: PropTypes.oneOf(Object.keys(defaultClassMapping)),
};

export default Typography;
