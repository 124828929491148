import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

const MeasurementTable = ({ tableDetails, showStripes = true }) => {
	if (!tableDetails || !tableDetails.columns || !tableDetails.data) {
		return null;
	}

	return (
		<div className="flex flex-col flex-grow md:-mx-12">
			<div className="overflow-x-auto">
				<table className="w-full table-fixed">
					<thead className="sticky top-0 bg-v2-off-white">
						<tr>
							{tableDetails.columns.map((column, key) => {
								return (
									<th
										className={classNames(
											'p-2 px-4 md:first-of-type:px-14',
											column.align === 'right' ? 'text-right' : 'text-left',
										)}
										key={key}>
										<Typography className="font-semibold text-gray-600">{column.title}</Typography>
									</th>
								);
							})}
						</tr>
					</thead>
					{tableDetails.data && (
						<tbody>
							{tableDetails.data.map((deployment, key) => {
								return (
									<tr
										key={key}
										className={classNames(showStripes ? 'even:bg-v2-cream hover:bg-gray-200' : '')}>
										{tableDetails.columns.map((column, key) => {
											return (
												<td
													className={classNames(
														'p-2 px-4 w-1/4 first-of-type:w-1/5 md:first-of-type:w-[12%] md:first-of-type:px-14',
														column.align === 'right'
															? 'text-right text-secondary-dark hover:text-secondary-darker hover:underline hover:underline-offset-2 hover:decoration-secondary-darker'
															: 'text-left',
													)}
													key={key}>
													{column.format
														? column.format(deployment[column.key])
														: deployment[column.key]}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					)}
				</table>
			</div>
		</div>
	);
};

MeasurementTable.propTypes = {
	showStripes: PropTypes.bool,
	tableDetails: PropTypes.object,
};

export default MeasurementTable;
