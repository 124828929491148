import BounceXWatcher from '/components/BounceXWatcher';
import CartPopup from '/components/cart/CartPopup';
import MultiProductPopup from '/components/promotions/MultiProductPopup';
import StackingPopup from '/components/promotions/StackingPopup';
import TopNav from '/components/TopNav';
import TrackLeadInit from '/components/TrackLeadInit';
import classNames from '/utils/class-names';
import dynamic from 'next/dynamic';
import { init as initShinestyAnalytics } from '/services/analytics/shinesty';
import { observer } from 'mobx-react-lite';
import { reactChildren } from '/utils/prop-types';
import { useEffect } from 'react';
import { useGlobalStore } from '/state/global-context';
import { useRouter } from 'next/router';
import { useUtmStore } from '/state/utm-context';

const CartPanel = dynamic(() => import('/components/CartPanel'));
const Footer = dynamic(() => import('/components/Footer'));

const MainLayout = ({ children }) => {
	const utms = useUtmStore();
	const globalStore = useGlobalStore();
	const router = useRouter();
	const currentPromotion = globalStore.getPromotionalConfig();

	useEffect(() => {
		utms.setUtmParams(router.query);
		initShinestyAnalytics(utms, router.query);
	}, [router.query]);

	return (
		<div className="flex flex-col justify-between h-screen bg-v2-off-white">
			<TopNav />
			<main
				className={classNames(
					'flex flex-col flex-grow bg-v2-off-white',
					globalStore.topPositionPaddingMain,
				)}>
				{children}
			</main>
			{((currentPromotion && currentPromotion.promotionType === 'stacking-gifts') ||
				currentPromotion.promotionType === 'prime-day') && <StackingPopup />}
			{currentPromotion && currentPromotion.promotionType === 'money-back' && <MultiProductPopup />}
			{currentPromotion &&
				(currentPromotion.promotionType === 'multi-product' ||
					currentPromotion.promotionType === 'cart-value') && <MultiProductPopup />}
			<Footer />
			<CartPanel />
			<CartPopup />
			<BounceXWatcher />
			<TrackLeadInit />
			{/* These styles come from global-store.js and are necessary for page rendering site wide. */}
			{false && (
				<>
					<div className="top-52 pt-32 pt-24 lg:pt-32 bg-v2-primary pt-[120px]" />
					<div className="pt-14 lg:pt-20" />
					<div className="top-40" />
					<div className="top-32" />
					<div className="top-24" />
					<div className="top-20" />
					<div className="top-14" />
					<div className="md:text-gray-600 lg:text-gray-600 md:text-white lg:text-white" />
				</>
			)}
		</div>
	);
};

MainLayout.propTypes = {
	children: reactChildren.isRequired,
};

export default observer(MainLayout);
