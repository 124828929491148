import { createGid } from '/utils/graph-ql';

const variantLookup = [
	{
		min: 0,
		variantId: '40008146354245',
		price: 0.99,
	},
	{
		min: 35,
		variantId: '40008146387013',
		price: 2.25,
	},
	{
		min: 50,
		variantId: '40008146419781',
		price: 3,
	},
	{
		min: 75,
		variantId: '40008146452549',
		price: 3.75,
	},
	{
		min: 100,
		variantId: '40008146485317',
		price: 4.5,
	},
	{
		min: 150,
		variantId: '40008146518085',
		price: 5.25,
	},
	{
		min: 200,
		variantId: '40008146550853',
		price: 6,
	},
	{
		min: 250,
		variantId: '40008146583621',
		price: 6.75,
	},
	{
		min: 300,
		variantId: '40008146616389',
		price: 7.5,
	},
	{
		min: 400,
		variantId: '40008146649157',
		price: 8.25,
	},
	{
		min: 500,
		variantId: '40008146681925',
		price: 9,
	},
	{
		min: 600,
		variantId: '40008146714693',
		price: 9.75,
	},
	{
		min: 700,
		variantId: '40008146747461',
		price: 10.5,
	},
];

export const getShippingProtectionLineItem = (subtotal) => {
	const targetPrice = subtotal * 0.019;
	const protection = variantLookup.reduce((acc, curr) => {
		if (curr.price >= targetPrice && (!acc || curr.price < acc.price)) {
			return curr;
		}
		return acc;
	}, variantLookup[variantLookup.length - 1]);

	return {
		merchandiseId: createGid('ProductVariant', protection.variantId),
		quantity: 1,
		attributes: [
			{
				key: 'Disclaimer',
				value:
					'This is an optional additional service for expedited replacements and is not a shipping fee.',
			},
		],
	};
};
