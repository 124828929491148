import axios from 'axios';
import { formatRecommendationsResponse } from './formatter';

export const getRecommendedProducts = async ({ productId, limit = 4 }) => {
	const baseUrl = `https://${process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID}.a.searchspring.io/boost/${process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID}/recommend`;

	try {
		const data = {
			siteId: process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID,
			products: [productId],
			profiles: [
				{
					tag: 'product-page',
					limit: limit,
				},
			],
		};

		return axios
			.post(baseUrl, data)
			.then((response) => formatRecommendationsResponse(response.data));
	} catch (error) {
		console.error('Searchspring Recommendations API Error:', error);
		throw error;
	}
};
